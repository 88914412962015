import React, { useEffect, useState } from 'react';
import "./ToggleButton.scss"
import { FaRegStar, FaStar } from 'react-icons/fa';
import { FaRankingStar } from "react-icons/fa6";
import { IoPeopleCircleOutline } from "react-icons/io5";


interface ToggleButtonProps {
    isToggled: boolean;
    onToggle: () => void;
    iconType: ToggleButtonType;
}


export enum ToggleButtonType {
    ToWatch,
    FeatureMatch,
    StreamMatch,
}


const ToggleButton: React.FC<ToggleButtonProps> = ({isToggled, onToggle, iconType}) => {
    const [toggledState, setToggledState] = useState<boolean>(false);


    useEffect(() => {
        setToggledState(isToggled);
    }, [isToggled, onToggle]);

    let icon = <FaStar />;

    switch (iconType) {
        case ToggleButtonType.ToWatch:
            icon = toggledState ? <FaStar className='toggle-icon-selected'/> : <FaRegStar className='toggle-icon-deselected'/>;
            break;
        case ToggleButtonType.FeatureMatch:
            icon = toggledState ? <FaRankingStar className='toggle-icon-selected'/> : <FaRankingStar className='toggle-icon-deselected'/>;
            break;
        case ToggleButtonType.StreamMatch:
            icon = toggledState ? <IoPeopleCircleOutline className='toggle-icon-selected'/> : <IoPeopleCircleOutline className='toggle-icon-deselected'/>;
            break;
        default:
            break;
    }

    return (
        <div className={`toggle-button ${isToggled ? 'toggle-button-selected' : ''}`} onClick={onToggle}>
            {icon}
        </div>
    );
};

export default ToggleButton;;