import { Component } from "react";
import "./Background.scss"
    
class Background extends Component {
    render()
    {
        return(
            <div>
                <div className="context">
            </div>
            <div className="area" >
                <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
        </div>
        )
    }
}

export default Background;