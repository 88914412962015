
import { apiResponse } from '../Models/Common/ApiResponse';
import axios, { AxiosError, AxiosResponse, Method } from 'axios';
import { Observable, from } from 'rxjs';
import { toolkitResult } from '../Models/Outbound/ToolkitResult';
import { liveStandings } from '../Models/Inbound/LiveStandings';
import { roundPairingWrapper } from '../Models/Outbound/RoundPairingWrapper';
import { streamDataResponse } from '../Models/Outbound/StreamDataResponse';
import { allEventsData } from '../Models/Outbound/AllEventsData';
import { eventData } from '../Models/Outbound/EventData';
import { tdfUploadConfirmation } from '../Models/Outbound/TdfUploadConfirmation';

export class ApiConnectService {
  apiRootUrl: string;
  apiEventUrl: string;
  apiRootUrlPrefix: string = process.env.REACT_APP_API_ROOT_URL_PREFIX ?? "https://";
  apiDomain: string = "/api/";
  eventDomain: string = "events/";
  allEventsExtension: string = "events";
  createEventExtension: string = "/create";
  putEventStateExtension: string = "/update-state";
  uploadExtension: string = "/tdf/upload";
  uploadFinalExtension: string = "/tdf/upload-final";
  uploadStandingsExtension: string = "/standings/upload/";
  getStandingsExtension: string = "/standings/";
  uploadStreamDataExtension: string = "/stream/upload/";
  getStreamDataExtension: string = "/stream/";
  getEndOfRoundExtension: string = "/pairings/endOfRound/";
  getPairingsTickerExtension: string = "/pairings/";
  getPairingsForRoundExtension: string = "/pairings/round/";

  usingLocalAPI = true;
  usingAzureDevAPI = true;

  constructor() {
    this.apiRootUrl = this.apiRootUrlPrefix + (process.env.REACT_APP_API_ROOT_URL ?? "_ENVIROMENT_VARIABLE_NOT_SET_") + this.apiDomain;
    this.apiEventUrl = this.apiRootUrl + this.eventDomain;
  }

  axiosConfig = {
    headers: {
      'AuthorizationToken': process.env.REACT_APP_API_FRONTEND_HEADER_AUTH_TOKEN ?? '_ENVIROMENT_VARIABLE_NOT_SET_',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Credentials':true,
    },
    params: {
    }
  };

  getAllEvents(): Observable<AxiosResponse<apiResponse<allEventsData>>> {
    console.log("api call - getAllEvents")
    return this.executeApiRequest(this.apiRootUrl + this.allEventsExtension, 'GET', null);
  }

  getEventById(eventId: string): Observable<AxiosResponse<apiResponse<eventData>>> {
    console.log("api call - getEventById")
    return this.executeApiRequest(this.apiEventUrl + eventId, 'GET', null);
  }

  createEvent(postBody: string, eventId: string): Observable<AxiosResponse<apiResponse<eventData>>> {
    console.log("api call - createEvent")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.createEventExtension, 'POST', postBody);
  }

  putEventState(putBody: string, eventId: string): Observable<AxiosResponse<apiResponse<eventData>>> {
    console.log("api call - putEventState")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.putEventStateExtension, 'PUT', putBody);
  }

  postTdfFile(postBody: string, eventId: string): Observable<AxiosResponse<apiResponse<tdfUploadConfirmation>>> {
    console.log("api call - postTdfFile")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.uploadExtension, 'POST', postBody);
  }

  postTdfFinalFile(postBody: string, eventId: string): Observable<AxiosResponse<apiResponse<tdfUploadConfirmation>>> {
    console.log("api call - postTdfFile")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.uploadFinalExtension, 'POST', postBody);
  }

  postStandings(postBody: string, eventId: string): Observable<AxiosResponse<apiResponse<liveStandings>>> {
    console.log("api call - postStandings")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.uploadStandingsExtension, 'POST', postBody);
  }

  postStreamData(postBody: string, eventId: string): Observable<AxiosResponse<apiResponse<streamDataResponse>>> {
    console.log("api call - postStreamData")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.uploadStreamDataExtension, 'POST', postBody);
  }

  getPairingsTicker(eventId: string): Observable<AxiosResponse<apiResponse<roundPairingWrapper>>> {
    console.log("api call - getPairingsTicker")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.getPairingsTickerExtension, 'GET', null);
  }

  getPairingsTickerForRound(eventId: string, round: number): Observable<AxiosResponse<apiResponse<roundPairingWrapper>>> {
    console.log("api call - getPairingsTickerForRound")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.getPairingsForRoundExtension + round, 'GET', null);
  }

  getStandings(eventId: string): Observable<AxiosResponse<apiResponse<liveStandings>>> {
    console.log("api call - getStandings")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.getStandingsExtension, 'GET', null);
  }

  getStreamData(eventId: string): Observable<AxiosResponse<apiResponse<streamDataResponse>>> {
    console.log("api call - getStreamData")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.getStreamDataExtension, 'GET', null);
  }

  getEndOfRoundMessage(eventId: string): Observable<AxiosResponse<apiResponse<toolkitResult>>> {
    console.log("api call - getEndOfRoundMessage")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.getEndOfRoundExtension, 'GET', null);
  }
  

  executeApiRequest(url: string, method: Method, body: string | null) {
    try {
      return from(axios.request({
        url: url,
        method: method,
        data: body,
        headers: this.axiosConfig.headers,
        params: this.axiosConfig.params
      })
      .catch(error => {
        if (error) {
        console.log(error)
        }
        if (error.response) {
        console.log(error.response)
        return error.response;
        }
        return error;
    }));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const serverError = error as AxiosError;
        if (serverError && serverError.response) {
          console.log(serverError.response.data);
          console.log(serverError.response.status);
          console.log(serverError.response.headers);
        }
      } else {
        console.error(error);
      }
      return from([]);
    }
  }
}