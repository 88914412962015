export enum eventState {
      New,
      Active,
      Finished,
      Cancelled,
      Deleted
}

export namespace eventState {
      export function name(state: eventState): string {
            switch (state) {
                  case eventState.New:
                        return "New";
                  case eventState.Active:
                        return "Active";
                  case eventState.Finished:
                        return "Finished";
                  case eventState.Cancelled:
                        return "Cancelled";
                  case eventState.Deleted:
                        return "Deleted";
                  default:
                        return "Unknown";
            }
      }
}