import GlassCard from "../../components/glassTheme/GlassCard";
import '../../components/glassTheme/Common.scss';
import './EventDetailsCard.scss';
import React, { Fragment, useEffect, useState } from 'react';
import { BehaviorSubject, Subscription } from "rxjs";
import { ApiConnectService } from "../../Services/ApiConnectService";
import { eventViewModel } from "../../EventsDashboard/EventsDashboard/EventsDashboard.ViewModel";
import { gameType } from "../../Models/Common/GameType";
import { GiCancel, GiConsoleController, GiDuck, GiRollingDices } from "react-icons/gi";
import { MdPhoneIphone } from "react-icons/md";
import { eventType } from "../../Models/Common/EventType";
import { eventSeason } from "../../Models/Common/EventSeason";
import { eventState } from "../../Models/Common/EventState";
import { GrEmptyCircle } from "react-icons/gr";
import { FaFlagCheckered, FaRegCirclePlay, FaRegTrashCan } from "react-icons/fa6";

interface EventDetailsCardProps {
  eventId: string;
  eventViewModelSubject: BehaviorSubject<eventViewModel | null>;
}

const standingsPlaceholder = "Paste Top 16 standings from TOM report here. Do not include the header row and check that the results are formatted correctly."
const acs = new ApiConnectService();

const EventDetailsCard: React.FC<EventDetailsCardProps> = ({
  eventId,
  eventViewModelSubject }) => {

  const [event, setEvent] = useState<eventViewModel | null>(null);

  useEffect(() => {
    var subs: Subscription[] = [];
    subs.push(eventViewModelSubject.subscribe((value) =>
      {
        setEvent(value?.deepCopy() ?? null);
      }));
    return () => subs.forEach(s => s.unsubscribe());
  }, [eventId, eventViewModelSubject]);

  if (event === null) {
    return <Fragment></Fragment>;
  }

  return (
    <Fragment>
      <div className='uploadElements'>
        <div className="cardWrapper">
          <GlassCard>
            <div className="cardInsert">
            <label className="glass-title">{event.details?.location} {eventType.name(event.details?.type)} {eventSeason.name(event.details?.season)}</label>
              <label className="glass-h3">{event.eventId}</label>
              {event.state !== eventState.Finished && <label className="glass-caption bold italic">Currently in Round {event.highestPairingsRound}</label> }
              <br/>
              <div className="detailscard-data">
                <div className="detailscard-data-column">
                  <div className="event-data-pair">
                      { event.state === eventState.New &&<GrEmptyCircle className="state-icon"/>}
                      { event.state === eventState.Active && <FaRegCirclePlay className="state-icon"/>}
                      { event.state === eventState.Finished && <FaFlagCheckered className="state-icon"/>}
                      { event.state === eventState.Cancelled && <GiCancel className="state-icon"/>}
                      { event.state === eventState.Deleted && <FaRegTrashCan className="state-icon"/>}
                      <label className="state-label">{eventState.name(event.state)}</label>
                  </div>
                  <div className="event-data-column">
                      { event.details?.gameType === gameType.TradingCardGame &&<GiRollingDices className="state-icon"/>}
                      { event.details?.gameType === gameType.VideoGame && <GiConsoleController className="state-icon"/>}
                      { event.details?.gameType === gameType.GO && <MdPhoneIphone className="state-icon"/>}
                      { event.details?.gameType === gameType.Other && <GiDuck className="state-icon"/>}
                      <label className="glass-caption italic">{gameType.shortName(event.details?.gameType)}</label>
                  </div>
                </div>

                <div className="detailscard-data-column">
                    <div className="event-data-pair">
                        <label className="event-detail-header"><i>Pairings Updated</i></label>
                        <label className="event-detail">{event.pairingsLastUpdated != "" ? (event.pairingsReadableLastUpdated + " (" + event.pairingsUpdateCount + " updates)"  ) : "Never"}</label>
                    </div>
                    <div className="event-data-pair">
                        <label className="event-detail-header"><i>Standings Updated</i></label>
                        <label className="event-detail">{event.standingsLastUpdated != "" ? (event.standingsReadableLastUpdated + " (" + event.standingsUpdateCount + " updates)"  ) : "Never"}</label>
                    </div>
                    <div className="event-data-pair">
                        <label className="event-detail-header"><i>Stream Data Updated:</i></label>
                        <label className="event-detail">{event.streamDataLastUpdated != "" ? (event.streamDataReadableLastUpdated + " (" + event.streamDataUpdateCount + " updates)"  ) : "Never"}</label>
                    </div>
                </div>
              </div>
            </div>
          </GlassCard>
          </div>
        </div>
    </Fragment>
  );
}
export default EventDetailsCard;