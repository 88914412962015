import { streamMatchTransfer } from "./StreamMatchTransfer";

export class streamDataTransfer {
    eventId: string;
    streamMatch: streamMatchTransfer | null;
    featureMatches: streamMatchTransfer[] | null;
    playersToWatch: string[] | null;

    constructor(eventId: string,
        streamMatch: streamMatchTransfer | null,
        featureMatches: streamMatchTransfer[] | null,
        playersToWatch: string[] | null)
        {
            this.eventId = eventId;
            this.streamMatch = streamMatch;
            this.featureMatches = featureMatches;
            this.playersToWatch = playersToWatch;
        }
}