import { useEffect, Fragment } from "react";
import { motion, useAnimationControls } from "framer-motion";
import '../../components/glassTheme/Common.scss';
import '../../components/glassTheme/Switch.scss';
import './EventsDashboard.scss';
import React from 'react';
import { eventsDashboardViewModel } from "./EventsDashboard.ViewModel";
import { useParams } from "react-router-dom";
import { BehaviorSubject, Subscription } from "rxjs";
import { navbarData } from "../../components/navbar/Navbar.ViewModel";
import EventsList from "../EventsList/EventsList";

const animationVariants = {
    offscreen: { delay: 0, opacity: 0, scale: 0.6, transition: { duration: 0.5 } },
    onscreen: { delay: 0, opacity: 1, scale: 1, transition: { duration: 0.2 } }
};

interface EventsDashboardProps {
    navbarDataSubject: BehaviorSubject<navbarData>;
}

const EventsDashboard: React.FC<EventsDashboardProps> = ({navbarDataSubject}) => {
    const edViewModel = new eventsDashboardViewModel(navbarDataSubject);

    return (
        <EventsDashboardConstructed viewModel={edViewModel}/>
    );
}

interface EventsDashboardConstructedProps {
    viewModel: eventsDashboardViewModel;
}

const EventsDashboardConstructed: React.FC<EventsDashboardConstructedProps> = ({ viewModel })  => {
    var animationControl = useAnimationControls()

    useEffect(() => {
        var subs: Subscription[] = [];
        return () => subs.forEach(s => s.unsubscribe());
    }, []);

    return (
        <Fragment>
            <motion.div
                initial="onscreen"
                animate={animationControl}
                variants={animationVariants}
                className="events-content">
                <EventsList
                    eventsSubject={viewModel.eventsSubject}
                    loadingStateSubject={viewModel.loadingStateSubject}
                    updateEventState={viewModel.updateEventState}/>
            </motion.div>
        </Fragment>
    );
}

export default EventsDashboard;