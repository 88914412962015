import React, { useEffect, useState } from 'react';
import "./HamburgerMenu.scss";
import { SlMenu } from "react-icons/sl";

interface HamburgerMenuProps {
}

export enum HamburgerMenuOptions {
    ToWatch,
    FeatureMatch,
    StreamMatch,
    StillPlaying,
    Active,
    Finished,
    Alphabetical,
    AllStates,
    LastUpdated,
    Default
}


const HamburgerMenu: React.FC<HamburgerMenuProps> = () => {
    const [toggledState, setToggledState] = useState<boolean>(false);

    let icon = <SlMenu className='hamburger-icon' />;
    let label: String = "";

    return (
        <div className="hamburger-button" onClick={() => setToggledState(!toggledState)}>
            <div className='hamburger-label-container'>
                <label className='hamburger-label'>{label}</label>
            </div>
            {icon}
        </div>
    );
};

export default HamburgerMenu;;