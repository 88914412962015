import React, { useEffect, useState } from 'react';
import "./Loader.scss"
import { loadingState } from '../../Models/Common/LoadingState';
import { BehaviorSubject, Subscription, delay } from 'rxjs';
import { VscDebugDisconnect } from "react-icons/vsc";
import { IoWarningOutline } from "react-icons/io5";
import { TbServer } from "react-icons/tb";
import { FaCircleCheck } from "react-icons/fa6";
import { motion, useAnimationControls } from 'framer-motion';

interface ToggleButtonProps {
    showMessage: boolean;
    spinnerType: SpinnerType;
    loadingStateSubject?: BehaviorSubject<loadingState>;
}

export enum SpinnerType {
    FullPage,
    Circular,
}

const Loader: React.FC<ToggleButtonProps> = ({showMessage, spinnerType, loadingStateSubject}) => {
    const [loaderState, setLoaderState] = useState<loadingState>(loadingState.initial);
    const [messageDelay, setmessageDelay] = useState(false);
    const animationControls = useAnimationControls();

    useEffect(() => {
        const timer = setTimeout(() => {
            setmessageDelay(true);
        }, 3000);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        var subs: Subscription[] = [];
        if (loadingStateSubject) {
            subs.push(loadingStateSubject.subscribe((state) => {
                if (state === loadingState.hidden) {
                    animationControls.start("offscreen");
                } else if (state === loadingState.loading || state === loadingState.initial) {
                    animationControls.start("basicTransition");
                } else if (state === loadingState.serverError || state === loadingState.networkError || state === loadingState.error || state === loadingState.success) {
                    animationControls.start("transition");
                }
                animationControls.start("transition");
                setLoaderState(state)
        }));
        } else {
            setLoaderState(loadingState.loading);
        }
        return () => subs.forEach(s => s.unsubscribe());
    }, [loadingStateSubject]);


    const animationVariants = {
        offscreen: { opacity: 0, rotate: [0, 30] },
        onscreen: { opacity: 1 },
        basicTransition: {
            scale: [1, 0, 1]
        },
        transition: {
            scale: [1, 1.2, 1],
            staggerChildren: 0.5,
            duration: 0.2
        }
    };

    let spinner = <div />;

    switch (spinnerType) {
        case SpinnerType.FullPage:
            spinner = <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            break;
        case SpinnerType.Circular:
            spinner = <span className="circular-loader"/>
            break;
        default:
            break;
    }

    const renderView = (state: loadingState) => {
        switch (state) {
            case loadingState.initial:
                return (
                    <motion.ol className='spinner-container'>
                    </motion.ol>
                );
            case loadingState.loading:
                return (
                    <motion.ol className='spinner-container'>
                        {spinner}
                        {messageDelay && showMessage && <div className='patience-message'>If unused for a while, the system<br/>may need ~10 seconds to wake up.</div>}
                    </motion.ol>
                );
            case loadingState.success:
                return (
                    <motion.ol className='spinner-container'>
                        <motion.li className='result-icon-background'>
                            <FaCircleCheck className='result-icon'/>
                        </motion.li>
                        {showMessage && <div className='patience-message'>Connected to server!</div>}
                    </motion.ol>
                );
            case loadingState.error:
                return (
                    <motion.ol className='spinner-container'>
                        <motion.li className='result-icon-background'>
                            <IoWarningOutline className='result-icon'/>
                        </motion.li>
                        {showMessage && <div className='patience-message'>An error has occurred.<br/>Check your network connection or contact an admin.</div>}
                    </motion.ol>
                );
            case loadingState.networkError:
                return (
                    <motion.ol className='spinner-container'>
                        <motion.li className='result-icon-background'>
                            <VscDebugDisconnect className='result-icon'/>
                        </motion.li>
                        {showMessage && <div className='patience-message'>Unable to connect to server.<br/>Check your network connection or contact an admin.</div>}
                    </motion.ol>
                );
            case loadingState.serverError:
                return (
                    <motion.ol className='spinner-container'>
                        <motion.li
                        className='result-icon-background'>
                            <TbServer className='result-icon'/>
                        </motion.li>
                        {showMessage && <div className='patience-message'>The server encountered an error and was not able to complete your request.<br/>Try again or contact an admin.</div>}
                    </motion.ol>
                );
            case loadingState.hidden:
                return;
            default:
                return (
                    <motion.ol className='spinner-container'>
                        {spinner}
                    </motion.ol>
                );
        }
    }

    return (
        <motion.div
            initial="onscreen"
            animate={animationControls}
            variants={animationVariants}>
                {renderView(loaderState)}
        </motion.div>
    )

};

export default Loader;