export enum matchOutcome {
    playing = 0,
    player1Win = 1,
    player2Win = 2,
    tie = 3,
    doubleLoss = 10,
    randomBye = 5,
    awardedBye = 4,
    forcedLoss = 8,
    deleted = 9,
    unknown = 404,
    undefined
}


export namespace matchOutcome {
    export function name(state: matchOutcome): string {
        switch (state) {
            case matchOutcome.playing: return "Playing";
            case matchOutcome.player1Win: return "Player 1 Win";
            case matchOutcome.player2Win: return "Player 2 Win";
            case matchOutcome.tie: return "Tie";
            case matchOutcome.doubleLoss: return "Double Loss";
            case matchOutcome.randomBye: return "Random Bye";
            case matchOutcome.awardedBye: return "Awarded Bye";
            case matchOutcome.forcedLoss: return "Forced Loss";
            case matchOutcome.deleted: return "Deleted";
            case matchOutcome.unknown: return "Unknown";
            case matchOutcome.undefined: return "Undefined";
        }
    }
}