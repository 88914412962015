export enum competitorDivision {
        Junior = 0,
        Senior = 1,
        Master = 2,
}

export namespace competitorDivision {
        export function name(state: competitorDivision): string {
        switch (state) {
                case competitorDivision.Junior:
                        return "Junior";
                case competitorDivision.Senior:
                        return "Senior";
                case competitorDivision.Master:
                        return "Master";
                default:
                        return "Unknown";
        }
        }
}