import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import GlassCard from '../../components/glassTheme/GlassCard';
import { SlMagicWand } from "react-icons/sl";
import './CreateEvent.scss';
import { CreateEventValidationError, CreateEventViewModel } from './CreateEvent.ViewModel';
import { navbarData } from '../../components/navbar/Navbar.ViewModel';
import { BehaviorSubject, Subscription } from 'rxjs';
import { eventType } from "../../Models/Common/EventType";
import { eventSeason } from "../../Models/Common/EventSeason";
import { gameType } from "../../Models/Common/GameType";
import UploadTracker, { UploadTrackerIcon } from '../../components/UploadTracker/UploadTracker';
import { eventDetails } from '../../Models/Common/EventDetails';
import { RequestResult } from '../../Models/Common/RequestResult';

interface CreateEventProps {
  navbarDataSubject: BehaviorSubject<navbarData>;
}

const CreateEvent: React.FC<CreateEventProps> = ({navbarDataSubject}) => {
  const edViewModel = new CreateEventViewModel(navbarDataSubject);

  return (
      <CreateEventConstructed viewModel={edViewModel}/>
  );
}

interface CreateEventConstructedProps {
  viewModel: CreateEventViewModel;
}

const CreateEventConstructed: React.FC<CreateEventConstructedProps> = ({ viewModel })  => {
  const eventIdPlaceholder = "Enter a unique Event ID..."
  const locationPlaceholder = "Enter the event's host city or similar descriptor."
  const [validationError, setValidationError] = React.useState<CreateEventValidationError | null>(null);
  const [uploadResult, setUploadResult] = React.useState<RequestResult<eventDetails> | null>(null);
  const [isUploading, setIsUploading] = React.useState(false);
  const [eventId, setEventId] = React.useState('');
  const [location, setLocation] = React.useState('');
  const [type, setEventType] = React.useState(eventType.Regional);
  const [season, setEventSeason] = React.useState(eventSeason.S2022);
  const [game, setGameType] = React.useState(gameType.TradingCardGame);
  
  const eventIdValueChange = (value: string) => {
    validateEvent();
    setEventId(value);
  }
  const locationValueChange = (value: string) => {
    validateEvent();
    setLocation(value);
  }
  const eventTypeChange = (value: eventType) => {
    validateEvent();
    setEventType(value);
  }
  const eventSeasonChange = (value: eventSeason) => {
    validateEvent();
    setEventSeason(value);
  }
  const gameTypeChange = (value: gameType) => {
    validateEvent();
    setGameType(value);
  }
  const validateEvent = () => {
    viewModel.validateEventDetails(eventId, location, type, season, game);
  }
  const createEvent = () => {
    validateEvent();
    viewModel.requestCreateEvent(eventId, location, type, season, game);
  }

  useEffect(() => {
    var subs: Subscription[] = [];
    subs.push(viewModel.validationError.subscribe(setValidationError));
    subs.push(viewModel.isUploading.subscribe(setIsUploading));
    subs.push(viewModel.uploadResult.subscribe(setUploadResult));
    return () => subs.forEach(s => s.unsubscribe());
  }, []);

  return (
    <div className="cardWrapper">
          <GlassCard>
          <div className="cardInsertWithFooter">
              <div className="validationContainer">
                <label className="glass-title">Create & Upload</label>
                
                <select className="glass-select" onChange={(e) => {
                    eventTypeChange(eventType[e.target.value as keyof typeof eventType] as eventType);
                  }}>
                  {Object.values(eventType)
                    .filter((v) => !isNaN(parseInt(v as string)))
                    .map(x => (
                        <option key={crypto.randomUUID()} value={parseInt(x as string)}>{eventType.name(parseInt(x as string) as eventType)}</option>
                  ))}
                </select>

                <select className="glass-select" onChange={(e) => {
                    eventSeasonChange(eventSeason[e.target.value as keyof typeof eventSeason] as eventSeason);
                  }}>
                  {Object.values(eventSeason)
                    .filter((v) => !isNaN(parseInt(v as string)))
                    .map(x => (
                        <option key={crypto.randomUUID()} value={parseInt(x as string)}>{eventSeason.name(parseInt(x as string) as eventSeason)}</option>
                  ))}
                </select>

                <select className="glass-select" onChange={(e) => {
                    gameTypeChange(gameType[e.target.value as keyof typeof gameType] as gameType);
                  }}>
                  {Object.values(gameType)
                    .filter((v) => !isNaN(parseInt(v as string)))
                    .map(x => (
                        <option key={crypto.randomUUID()} value={parseInt(x as string)}>{gameType.name(parseInt(x as string) as gameType)}</option>
                  ))}
                </select>

                <input className="glass-textarea" placeholder={eventIdPlaceholder} onChange={(e) => eventIdValueChange(e.target.value)}></input>
                <input className="glass-textarea" placeholder={locationPlaceholder} onChange={(e) => locationValueChange(e.target.value)}></input>

                {(uploadResult == null && !isUploading) &&
                  <div className='validationContainer'>
                    {validationError == CreateEventValidationError.initialState && <label className="validationMessage"><i>This information directly affects stream data. Please enter carefully-formatted and accurate values.</i></label>}
                    {validationError == CreateEventValidationError.eventIdError && <label className="validationMessage"><i>Event ID invalid - use letters or numbers only, limited to 15 characters.</i></label>}
                    {validationError == CreateEventValidationError.eventLocationError && <label className="validationMessage"><i>Event location is not valid.</i></label>}
                    {validationError == CreateEventValidationError.eventSeasonError && <label className="validationMessage"><i>Event Season is invalid.</i></label>}
                    {validationError == CreateEventValidationError.eventTypeError && <label className="validationMessage"><i>Event Type is invalid.</i></label>}
                    {validationError == CreateEventValidationError.gameTypeError && <label className="validationMessage"><i>Game Type is invalid.</i></label>}
                    {validationError == CreateEventValidationError.creationError && <label className="validationMessage"><i>Error creating event. Check your connection and confirm the eventId is unique.</i></label>}
                    {validationError == CreateEventValidationError.otherError && <label className="validationMessage"><i>Unexpected problem with creating event.</i></label>}
                  </div>
                }
                {(uploadResult != null && uploadResult?.isSuccess()) ?
                    <a className="glass-body" href={"/events/"+eventId} target="">
                      <button className="createButton">Go to Upload Dashboard <SlMagicWand className="glass-button-icon-right"/></button>
                    </a>
                  : 
                  <button className="createButton"
                    disabled={validationError != null}
                    onClick={() => createEvent()}>
                      Create Event <SlMagicWand className="glass-button-icon-right"/>
                  </button>
                }
              </div>
            </div>
            </GlassCard>
            <div className="card-footer">
              { viewModel &&
                <UploadTracker
                  isUploading={isUploading}
                  uploadResult={uploadResult}
                  defaultIcon={UploadTrackerIcon.Document}
                  defaultTitle={"Configure a new event"}
                  defaultSubtitle={"Upload information will appear here."}
                  uploadMessage="Creating event..."/>
              }
            </div>
          </div>
  );
}

export default CreateEvent;