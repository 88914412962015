import { Component, PropsWithChildren } from "react";
import "./Common.scss"

class GlassCard extends Component<PropsWithChildren<{}>, {}> {

    render()
    {
        return(
            <nav className="glass-card">
                {this.props.children}
            </nav>
        )
    }
}

export default GlassCard;