import { useState, useEffect, Fragment } from "react";
import { motion, useAnimationControls } from "framer-motion";
import '../../components/glassTheme/Common.scss';
import '../../components/glassTheme/Switch.scss';
import ToggleButton, { ToggleButtonType } from "../../components/ToggleButton/ToggleButton";
import Loader, { SpinnerType } from "../../components/Spinner/Loader";
import './Match.scss';
import React from 'react';
import { BehaviorSubject, Subscription } from "rxjs";
import { MdOutlineKeyboardDoubleArrowLeft, MdOutlineKeyboardDoubleArrowRight, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { FaStar, FaCircle } from 'react-icons/fa';
import { FaRankingStar, } from "react-icons/fa6";
import { streamDataViewModel } from "../StreamDataControls/StreamDataControls.ViewModel";
import { matchViewModel } from "./Match.ViewModel";
import { matchOutcome } from "../../Models/Common/MatchOutcome";

interface MatchProps {
    match: matchViewModel;
    streamDataSubject: BehaviorSubject<streamDataViewModel | null>;
    isHighlightedMatch: boolean;
    toggleHighlightedMatch: (match: matchViewModel) => void;
    toggleStreamMatch: (match: matchViewModel) => void;
    togglePlayerToWatch: (playerId: string) => void;
    toggleFeatureMatch: (match: matchViewModel) => void;
}

const Match: React.FC<MatchProps> = ({ match, streamDataSubject, toggleStreamMatch, isHighlightedMatch, toggleHighlightedMatch, togglePlayerToWatch, toggleFeatureMatch }) => {
    const [streamData, setStreamData] = useState<streamDataViewModel | null>(null);
    const [isSelected, setSelected] = useState<boolean>(false);
    const animationControls = useAnimationControls();
    const selectedAnimation = useAnimationControls();

    useEffect(() => {
        animationControls.start("onscreen");
        var subs: Subscription[] = [];
        subs.push(streamDataSubject.subscribe(setStreamData));
        setSelected(isHighlightedMatch);
        return () => subs.forEach(s => s.unsubscribe());
    }, [streamDataSubject, isHighlightedMatch, animationControls]);

    useEffect(() => {
        if (isSelected) {
            selectedAnimation.start("onscreen");
        } else {
            selectedAnimation.start("offscreen");
        }
    }, [isSelected]);

    if (match === null) {
        return <Loader showMessage={true} spinnerType={SpinnerType.FullPage} />;
    }

    const animationVariants = {
        offscreen: { opacity: 0, y: -10 },
        onscreen: { opacity: 1, y: 0 }
    };

    const selectedAnimationVariantsLeft = {
        offscreen: { opacity: 0, x: 50 },
        onscreen: { opacity: 1, x: 0, transition: { duration: 0.2 } }
    };

    const selectedAnimationVariantsRight = {
        offscreen: { opacity: 0, x: -50 },
        onscreen: { opacity: 1, x: 0, transition: { duration: 0.2 } }
    };


    return (
        <Fragment>
        <motion.div
        className="match-card"
            initial="offscreen"
            animate={animationControls}
            variants={animationVariants}
            transition={{ duration: 0.2 }}
            >
            <div
                className={isSelected ? "match dark-glass-bubble" : match.outcome === matchOutcome.playing ? "match glass-bubble" : "match dim-glass-bubble"}>
                {match.player1.isPlayerToWatch? <FaStar className="player-icon-left"/> : ""}
                {match.player2 && match.player2.isPlayerToWatch? <FaStar className="player-icon-right"/> : ""}
                <div className="player">
                    <div className="player-details">
                        <label className="player-name">{match.player1.name}</label>
                        <label className="glass-h2">{match.player1.wins} / {match.player1.losses} / {match.player1.ties}</label>
                    </div>
                </div>
                <div className="matchControls">
                <div className="outcomeControls">
                        {[matchOutcome.player1Win, matchOutcome.randomBye,matchOutcome.awardedBye].includes(match.outcome) && <MdOutlineKeyboardDoubleArrowLeft className="outcome-icon"/>}
                        {[matchOutcome.tie].includes(match.outcome) && <MdOutlineKeyboardArrowLeft className="outcome-icon"/>}
                </div>
                    {isSelected ? (
                        <motion.div
                            className="matchControlsWrite"
                            initial="offscreen"
                            animate={selectedAnimation}
                            variants={selectedAnimationVariantsLeft}
                            transition={{ duration: 0.2 }}>
                            <ToggleButton isToggled={match.isStreamMatch} onToggle={() => {toggleStreamMatch(match)}} iconType={ToggleButtonType.StreamMatch}/>
                            <ToggleButton isToggled={match.player1.isPlayerToWatch} onToggle={() => {togglePlayerToWatch(match.player1.playerId)}} iconType={ToggleButtonType.ToWatch}/>
                        </motion.div>
                    ) : ( <div className="matchControlsRead">

                        </div>
                    )}
                    <div className="matchSeparator" onClick={() => toggleHighlightedMatch(match)}>
                        {match.isStreamMatch && <label className="glass-h2">stream</label>}
                        {!match.player2 ? <label className="glass-h2">{match.outcome==matchOutcome.forcedLoss ? 'loss' : 'bye'}</label>
                            : <label className="glass-h2">{match.tableNumber}</label>}
                    {match.isFeatureMatch? <FaRankingStar className="favourite-icon"/> : ""}
                    </div>
                    {isSelected ? (
                        <motion.div
                            className="matchControlsWrite"
                            initial="offscreen"
                            animate={selectedAnimation}
                            variants={selectedAnimationVariantsRight}
                            transition={{ duration: 0.2 }}>
                        <ToggleButton isToggled={match.isFeatureMatch} onToggle={() => {toggleFeatureMatch(match)}} iconType={ToggleButtonType.FeatureMatch}/>
                        <ToggleButton isToggled={match.player2?.isPlayerToWatch ?? false} onToggle={() => {togglePlayerToWatch(match.player2!.playerId)}} iconType={ToggleButtonType.ToWatch}/>
                        </motion.div>
                    ) : ( <div className="matchControlsRead">

                        </div>
                    )}
                    <div className="outcomeControls">
                        {[matchOutcome.tie].includes(match.outcome) && <MdOutlineKeyboardArrowRight className="outcome-icon"/>}
                        {[matchOutcome.player2Win].includes(match.outcome) && <MdOutlineKeyboardDoubleArrowRight className="outcome-icon"/>}
                    </div>
                    </div>
                <div className="player">
                    {match.player2 && <div>
                        <div className="player-details">
                            <label className="player-name">{match.player2.name}</label>
                            <label className="glass-h2">{match.player2.wins} / {match.player2.losses} / {match.player2.ties}</label>
                        </div>
                    </div>}
                </div>
            </div>
        </motion.div>
        </Fragment>
    )
}

export default Match;