import { BehaviorSubject, combineLatest } from "rxjs";
import { ApiConnectService } from "../../Services/ApiConnectService";
import { TournamentDataService } from "../../Services/TournamentDataService";
import { navbarData } from "../../components/navbar/Navbar.ViewModel";
import { eventType } from "../../Models/Common/EventType";
import { eventSeason } from "../../Models/Common/EventSeason";
import { gameType } from "../../Models/Common/GameType";
import { eventDetails } from "../../Models/Common/EventDetails";
import { RequestResult } from "../../Models/Common/RequestResult";

export enum CreateEventValidationError {
  eventIdError,
  eventLocationError,
  eventTypeError,
  eventSeasonError,
  gameTypeError,
  creationError,
  otherError,
  initialState,
}

export class CreateEventViewModel {
    navbarData: BehaviorSubject<navbarData>;
    uploadResult = new BehaviorSubject<RequestResult<any> | null>(null);
    isUploading = new BehaviorSubject<boolean>(false);
    validationError = new BehaviorSubject<CreateEventValidationError | null>(CreateEventValidationError.initialState);

    acs = new ApiConnectService();
    tournamentService = new TournamentDataService(this.acs);

    constructor(
      navbarDataSubject: BehaviorSubject<navbarData>,
    ) {
        this.navbarData = navbarDataSubject;
    }

    validateEventDetails = (
      eventId: string,
      location: string,
      eventType: eventType,
      eventSeason: eventSeason,
      gameType: gameType) =>
    {
      if (!eventId || !this.validateEventId(eventId)) { this.validationError.next(CreateEventValidationError.eventIdError); return; };
      if (!location || !this.validateEventLocation(location)) { this.validationError.next(CreateEventValidationError.eventLocationError); return; };
      if (eventType == null) { this.validationError.next(CreateEventValidationError.eventTypeError); return; };
      if (eventSeason == null) { this.validationError.next(CreateEventValidationError.eventSeasonError); return; };
      if (gameType == null) { this.validationError.next(CreateEventValidationError.gameTypeError); return; };
      this.validationError.next(null);
    }
  
    requestCreateEvent = (
      eventId: string,
      location: string,
      eventType: eventType,
      eventSeason: eventSeason,
      gameType: gameType) => {
      var eventDetails: eventDetails = {
        location: location,
        type: eventType,
        season: eventSeason,
        gameType: gameType
      };
      console.log("Requesting creation of event with ID: " + eventId);
      this.isUploading.next(true);
      this.tournamentService.CreateEvent(eventId, eventDetails).subscribe(result => {
        if (!(result && result.result?.eventId == eventId)) {
          this.validationError.next(CreateEventValidationError.creationError);
        }
        this.uploadResult.next(null);
        this.uploadResult.next(result);
        this.isUploading.next(false);
      });
    }

    private validateEventId (eventId: string): boolean {
      const regex = /^[a-zA-Z0-9]{1,15}$/;
      return regex.test(eventId);
    }

    private validateEventLocation (location: string): boolean {
      const regex = /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/;
      return regex.test(location);
    }
}