import { streamDataResponse } from "../../Models/Outbound/StreamDataResponse";
import { streamMatchTransfer } from "../../Models/Inbound/StreamMatchTransfer";
import { competitorViewModel, streamMatchViewModel } from "../Match/Match.ViewModel";
import { BehaviorSubject, first } from "rxjs";
import { EditStreamMatchAction } from "../PairingsDashboard/PairingsDashboard.ViewModel";
import { RequestResult } from "../../Models/Common/RequestResult";
import { CommonFormatsService } from "../../Services/CommonFormatsService";

export class streamDataHighlightedMatch {
    isStreamMatch: boolean;
    isFeatureMatch: boolean;
    data: streamMatchViewModel;

    constructor(
        isStreamMatch: boolean,
        isFeatureMatch: boolean,
        data: streamMatchViewModel
    ) {
        this.isStreamMatch = isStreamMatch;
        this.isFeatureMatch = isFeatureMatch;
        this.data = data;
    }
}

export class streamDataViewModel {
    eventId: string;
    lastUpdated: string;
    streamMatch: streamMatchViewModel | null;
    featureMatches: streamMatchViewModel[] | null;
    playersToWatch: string[];
    highlightedMatch = new BehaviorSubject<streamDataHighlightedMatch | null>(null);
    editFeatureMatch: (match: streamMatchViewModel | null, action: EditStreamMatchAction) => void;
    editStreamMatch: (match: streamMatchViewModel | null, action: EditStreamMatchAction) => void;
    deletedFeatureMatchIndex: number | null = null;
    showTooltip: boolean = false;
    isUploading: boolean = false;
    uploadResult: RequestResult<streamDataResponse> | null;

    constructor(eventId: string, apiStreamData?: streamDataResponse, uploadResult?: RequestResult<streamDataResponse>) {
        this.eventId = eventId;
        if (apiStreamData) {
            this.lastUpdated = CommonFormatsService.formatOptionalDate(apiStreamData.lastUpdated);
            this.streamMatch = apiStreamData.streamMatch ? this.createStreamMatchViewModel(apiStreamData.streamMatch) : null;
            this.featureMatches = [];
            apiStreamData.featureMatches?.forEach(match => {
                try {
                    var convertedMatch = this.createStreamMatchViewModel(match)
                    this.featureMatches?.push(convertedMatch);
                } catch (error) {
                    console.log("Error converting match: " + error)
                }
            });
            this.playersToWatch = apiStreamData.playersToWatch;
        } else {
            this.featureMatches = [];
            this.playersToWatch = [];
        }
        this.uploadResult = uploadResult ?? null;
    }

    createStreamMatchViewModel(apiStreamMatch: streamMatchTransfer): streamMatchViewModel {
        return new streamMatchViewModel(
            apiStreamMatch.tableNumber,
            apiStreamMatch.outcome,
            apiStreamMatch.round,
            new competitorViewModel(
                apiStreamMatch.player1.playerId,
                apiStreamMatch.player1.firstName,
                apiStreamMatch.player1.lastName,
                apiStreamMatch.player1.countryCode ?? null,
                apiStreamMatch.player1.wins,
                apiStreamMatch.player1.losses,
                apiStreamMatch.player1.ties
            ),
            apiStreamMatch.player2 ? new competitorViewModel(
                apiStreamMatch.player2.playerId,
                apiStreamMatch.player2.firstName,
                apiStreamMatch.player2.lastName,
                apiStreamMatch.player2.countryCode ?? null,
                apiStreamMatch.player2.wins,
                apiStreamMatch.player2.losses,
                apiStreamMatch.player2.ties
            ) : null,
            apiStreamMatch.resultMessage,
            apiStreamMatch.shouldSwitchPlayers,
            apiStreamMatch.featureMatchIndex,
        );
    }

    toggleHighlightedMatch = (match: streamMatchViewModel, isStreamMatch: boolean, isFeatureMatch: boolean) => {
        if (this.highlightedMatch.getValue()?.data === match) {
            this.highlightedMatch.next(null);
            return;
        }
        let highlightedMatch = new streamDataHighlightedMatch(
            isStreamMatch,
            isFeatureMatch,
            match
        );
        if (this.highlightedMatch.getValue() === highlightedMatch) {
            this.highlightedMatch.next(null);
        } else {
            this.highlightedMatch.next(highlightedMatch);
        }
    }

    editHighlightedMatch = (action: EditStreamMatchAction) => {
        this.highlightedMatch.pipe(first()).subscribe(highlightedMatch => {
            if (highlightedMatch) {
                if (highlightedMatch.isStreamMatch && action === EditStreamMatchAction.Delete) {
                    this.editStreamMatch(highlightedMatch.data, action);
                    highlightedMatch = null;
                } else if (highlightedMatch.isFeatureMatch) {
                    if (action === EditStreamMatchAction.Delete) {
                        this.deletedFeatureMatchIndex = highlightedMatch.data.featureMatchIndex;
                    }
                    this.editFeatureMatch(highlightedMatch.data, action);
                } else if (action === EditStreamMatchAction.ToggleSwitchPlayers) {
                    if (highlightedMatch.isStreamMatch) {
                        this.editStreamMatch(highlightedMatch.data, action);
                    } else if (highlightedMatch.isFeatureMatch) {
                        this.editFeatureMatch(highlightedMatch.data, action);
                    }
                }
            }
        });
    }

    toggleTooltip = () => {
        this.showTooltip = !this.showTooltip;
        console.log("show tooltip" + this.showTooltip);
    }

    setUploading = () => {
        this.isUploading = true;
    }
}