import { ApiConnectService } from './ApiConnectService';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { apiResponse } from '../Models/Common/ApiResponse';
import { AxiosResponse } from 'axios';
import { toolkitResult } from '../Models/Outbound/ToolkitResult';
import { tdfTransfer } from '../Models/Inbound/TdfTransfer';
import { standingsReportTransfer } from '../Models/Inbound/StandingsReportTransfer';
import { liveStandings } from '../Models/Inbound/LiveStandings';
import { roundPairingWrapper } from '../Models/Outbound/RoundPairingWrapper';
import { streamDataResponse } from '../Models/Outbound/StreamDataResponse';
import { streamDataTransfer } from '../Models/Inbound/StreamDataTransfer';
import { allEventsData } from '../Models/Outbound/AllEventsData';
import { eventData } from '../Models/Outbound/EventData';
import { eventState } from '../Models/Common/EventState';
import { tdfUploadConfirmation } from '../Models/Outbound/TdfUploadConfirmation';
import { RequestResult } from '../Models/Common/RequestResult';
import { tdfFinalTransfer } from '../Models/Inbound/TdfFinalTransfer';
import { eventDetailsTransfer } from '../Models/Inbound/EventDetailsTransfer';
import { eventDetails } from '../Models/Common/EventDetails';
import { eventStateTransfer } from '../Models/Inbound/EventStateTransfer';

export class TournamentDataService {

    constructor(private acs: ApiConnectService) { }

    public GetAllEvents(): Observable<RequestResult<allEventsData>> {
        var apiAllEvents;
        console.log("Getting events list");
        apiAllEvents = this.acs.getAllEvents().pipe(map((response: AxiosResponse<apiResponse<allEventsData>>) => {
            return new RequestResult(response);
            }));
        return apiAllEvents;
    }

    public GetEventById(eventId: string): Observable<RequestResult<eventData>> {
        var apiEvent;
        console.log("Getting events with ID " + eventId);
        apiEvent = this.acs.getEventById(eventId).pipe(map((response: AxiosResponse<apiResponse<eventData>>) => {
            return new RequestResult(response);
            }));
        return apiEvent;
    }

    public CreateEvent(eventId: string, eventDetails: eventDetails): Observable<RequestResult<eventData>> {
        var apiEvent;
        var eventDetailsTransfer: eventDetailsTransfer = { eventDetails: eventDetails };
        var uploadPayload = JSON.stringify(eventDetailsTransfer);
        console.log("Getting events with ID " + eventId);
        apiEvent = this.acs.createEvent(uploadPayload, eventId).pipe(map((response: AxiosResponse<apiResponse<eventData>>) => {
            return new RequestResult(response);
            }));
        return apiEvent;
    }

    public PutEventState(eventId: string, eventState: eventState): Observable<RequestResult<eventData>> {
        var apiEvent;
        var eventStateTransfer: eventStateTransfer = { eventState: eventState };
        var uploadPayload = JSON.stringify(eventStateTransfer);
        console.log("Updating event state for event with ID " + eventId);
        apiEvent = this.acs.putEventState(uploadPayload, eventId).pipe(map((response: AxiosResponse<apiResponse<eventData>>) => {
            return new RequestResult(response);
            }));
        return apiEvent;
    }

    public GetPairingsData(eventId: string, round: number | null): Observable<RequestResult<roundPairingWrapper>> {
        var requestResult;
        if (round) {
            console.log("Getting pairings data for event " + eventId + ", round number " + round);
            requestResult = this.acs.getPairingsTickerForRound(eventId, round).pipe(map((response: AxiosResponse<apiResponse<roundPairingWrapper>>) => {
                console.log("Pairings loaded for event " + response?.data?.result?.eventId + ", round number " + response?.data?.result?.round);
                return new RequestResult(response);
            }));
        } else {
            console.log("Getting latest pairings data for event " + eventId);
            requestResult = this.acs.getPairingsTicker(eventId).pipe(map((response: AxiosResponse<apiResponse<roundPairingWrapper>>) => {
                console.log("Pairings loaded for event " + response?.data?.result?.eventId + ", round number " + response?.data?.result?.round);
                return new RequestResult(response);
            }));
        }
        return requestResult;
    }

    public GetPairingsTicker(eventId: string): Observable<RequestResult<string>> {
        var requestResult = this.acs.getPairingsTicker(eventId).pipe(map((response: AxiosResponse<apiResponse<roundPairingWrapper>>) => {
            return new RequestResult(response).changeType<string>(response?.data?.result?.pairingsTicker ?? "No data found");
            }));
        return requestResult;
    }

    public GetLiveResultsTicker(eventId: string): Observable<RequestResult<string>> {
        var requestResult = this.acs.getPairingsTicker(eventId).pipe(map((response: AxiosResponse<apiResponse<roundPairingWrapper>>) => {
                return new RequestResult(response).changeType<string>(response?.data?.result?.liveResultsTicker ?? "No data found");
            }));
        return requestResult;
    }

    public GetStandingsTicker(eventId: string): Observable<RequestResult<string>> {
        var requestResult = this.acs.getStandings(eventId).pipe(map((response: AxiosResponse<apiResponse<liveStandings>>) => {
            return new RequestResult(response).changeType<string>(response?.data?.result?.standingsTicker ?? "No data found");
            }));
        return requestResult;
    }

    public GetEndOfRoundMessage(eventId: string): Observable<RequestResult<string>> {
        var requestResult = this.acs.getEndOfRoundMessage(eventId).pipe(map((response: AxiosResponse<apiResponse<toolkitResult>>) => {
            return new RequestResult(response).changeType<string>(response?.data?.result?.resultMessage ?? "No data found");
            }));
        return requestResult;
    }

    public UploadTdfFile(tdfTransfer: tdfTransfer, eventId: string): Observable<RequestResult<tdfUploadConfirmation>> {
        var uploadPayload = JSON.stringify(tdfTransfer);
        var requestResult = this.acs.postTdfFile(uploadPayload, eventId).pipe(map((response: AxiosResponse<apiResponse<tdfUploadConfirmation>>) => {
                return new RequestResult(response);
            }));
        return requestResult;
    };

    public UploadFinalTdfFile(tdfTransfer: tdfFinalTransfer, eventId: string): Observable<RequestResult<tdfUploadConfirmation>> {
        var uploadPayload = JSON.stringify(tdfTransfer);
        var requestResult = this.acs.postTdfFinalFile(uploadPayload, eventId).pipe(map((response: AxiosResponse<apiResponse<tdfUploadConfirmation>>) => {
                return new RequestResult(response);
            }));
        return requestResult;
    };

    public GetStreamData(eventId: string): Observable<RequestResult<streamDataResponse>> {
        console.log("Getting stream data for event " + eventId);
        var requestResult = this.acs.getStreamData(eventId).pipe(map((response: AxiosResponse<apiResponse<streamDataResponse>>) => {
                return new RequestResult(response);
            }));
        return requestResult;
    }

    public PostStreamData(streamData: streamDataTransfer, eventId: string): Observable<RequestResult<streamDataResponse>> {
        var uploadPayload = JSON.stringify(streamData);
        var requestResult = this.acs.postStreamData(uploadPayload, eventId).pipe(map((response: AxiosResponse<apiResponse<streamDataResponse>>) => {
                return new RequestResult(response);
            }));
        return requestResult;
    };

    public PostTournamentStandingsReport(standingsTransfer: standingsReportTransfer, eventId: string): Observable<RequestResult<liveStandings>> {
        var uploadPayload = JSON.stringify(standingsTransfer);
        var requestResult = this.acs.postStandings(uploadPayload, eventId).pipe(map((response: AxiosResponse<apiResponse<liveStandings>>) => {
                return new RequestResult(response);
            }));
        return requestResult;
    };
}
