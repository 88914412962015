import { time } from "console";

export class CommonFormatsService {
    public static formatOptionalDate = (dateString: string | undefined) => {
        if (!dateString) return "";
        const dateOptions: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: "UTC",
        };
        return new Date(dateString).toLocaleString("en-GB", dateOptions) + " UTC";
    }

    public static formatClientFallbackDate = (dateString: string | undefined) => {
        const dateOptions: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: "UTC",
        };

        if (!dateString) {
            return new Date().toLocaleString("en-GB", dateOptions) + " UTC (client time)";
        }

        return new Date(dateString).toLocaleString("en-GB", dateOptions) + " UTC";
    }
}
