// src/utils/indexedDB.ts
import { openDB } from 'idb';

const DB_NAME = 'file-system';
const STORE_NAME = 'fileHandles';

const dbPromise = openDB(DB_NAME, 1, {
    upgrade(db) {
        db.createObjectStore(STORE_NAME);
    },
});

export const saveFileHandle = async (name: string, handle: FileSystemFileHandle) => {
    const db = await dbPromise;
    await db.put(STORE_NAME, handle, name);
};

export const getFileHandle = async (name: string): Promise<FileSystemFileHandle | undefined> => {
    const db = await dbPromise;
    return db.get(STORE_NAME, name);
};

export const removeFileHandle = async (name: string) => {
    const db = await dbPromise;
    await db.delete(STORE_NAME, name);
};