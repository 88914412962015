import React, { useEffect, useState } from 'react';
import "./UploadTracker.scss"
import { RequestResult, RequestResultType } from '../../Models/Common/RequestResult';
import { motion } from 'framer-motion';
import { FaXmark, FaCheck, FaExclamation, FaArrowUp} from "react-icons/fa6";
import { FaRegFileAlt, FaRegListAlt } from "react-icons/fa";
import { GrSync } from "react-icons/gr";

interface UploadTrackerProps {
    isUploading: boolean;
    uploadResult: RequestResult<any> | null;
    defaultIcon: UploadTrackerIcon;
    defaultTitle: string;
    defaultSubtitle: string;
    uploadMessage: string;
}

export enum UploadTrackerIcon {
    Document,
    Report,
    StreamData
}

const UploadTracker: React.FC<UploadTrackerProps> = ({isUploading, uploadResult, defaultIcon, defaultTitle, defaultSubtitle, uploadMessage}) => {
    const [uploadTimerMessage, setUploadTimerMessage] = useState<string | null>(null);
    const [result, setResult] = useState<RequestResult<any> | null>(null);

    useEffect(() => {
        setResult(uploadResult);
        console.log("Rendering: " + uploadResult?.summaryTitle)
        if (isUploading) {
            const startTime = Date.now();
            const timer = setInterval(() => {
                const elapsedTime = Date.now() - startTime;
                setUploadTimerMessage("Started " + (elapsedTime / 1000).toFixed(0) + " seconds ago");
            }, 1000);
            return () => {
                    clearInterval(timer);
                    setUploadTimerMessage("Started 0 seconds ago");
                }
        }
    }, [isUploading, uploadResult]);

    const getDefaultIcon = () => {
        switch (defaultIcon) {
            case UploadTrackerIcon.Document:
                return <FaRegFileAlt className='resultIcon'/>;
            case UploadTrackerIcon.Report:
                return <FaRegListAlt className='resultIcon'/>;
            case UploadTrackerIcon.StreamData:
                return <GrSync className='resultIcon'/>;
        }
    }

    const renderView = () => {
            if (isUploading) {
                return (
                    <div className="uploadTrackerUploading">
                        <div className="resultIndicatorUploading"><FaArrowUp className="uploadingIcon"/></div>
                        <div className="uploadTrackerData">
                            <label className="resultTitle">{uploadMessage}</label>
                            <label className="resultSubtitle">{uploadTimerMessage}</label>
                        </div>
                    </div>
                );
            } else if (result == null) {
                return (
                    <div className="uploadTrackerDefault">
                        <div className="resultIndicatorDefault">{getDefaultIcon()}</div>
                        <div className="uploadTrackerData">
                            <label className="resultTitle">{defaultTitle}</label>
                            <label className="resultSubtitle">{defaultSubtitle}</label>
                        </div>
                    </div>
                );
            } else if (result.resultType == RequestResultType.ClientError) {
                return (
                    <div className="uploadTrackerWarning">
                        <div className="resultIndicatorWarning"><FaExclamation className="resultIcon"/></div>
                        <div className="uploadTrackerData">
                            <label className="resultTitle">{result.summaryTitle}</label>
                            <label className="resultSubtitle">{result.timestamp}</label>
                        </div>
                    </div>
                );
            } else if (result.resultType == RequestResultType.Success) {
                return (
                    <div className="uploadTrackerSuccess">
                        <div className="resultIndicatorSuccess"><FaCheck className="resultIcon"/></div>
                        <div className="uploadTrackerData">
                            <label className="resultTitle">{result.summaryTitle}</label>
                            <label className="resultSubtitle">{result.timestamp}</label>
                        </div>
                    </div>
                );
            } else if (result.resultType == RequestResultType.ConnectionError) {
                return (
                    <div className="uploadTrackerWarning">
                        <div className="resultIndicatorWarning"><FaExclamation className="resultIcon"/></div>
                        <div className="uploadTrackerData">
                            <label className="resultTitle">{result.summaryTitle}</label>
                            <label className="resultSubtitle">{result.timestamp}</label>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="uploadTrackerError">
                        <div className="resultIndicatorError"><FaXmark className="resultIcon"/></div>
                        <div className="uploadTrackerData">
                            <label className="resultTitle">{result.summaryTitle}</label>
                            <label className="resultSubtitle">{result.timestamp}</label>
                        </div>
                    </div>
                );
            }
        }

    return (
        <motion.div>
            {renderView()}
        </motion.div>
    )

};

export default UploadTracker;