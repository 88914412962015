import { useEffect, useState } from "react";
import "./SearchBar.scss"
import React from "react";
import '../glassTheme/Common.scss';
import { FaSearch } from "react-icons/fa";
import { ImCancelCircle } from "react-icons/im";


interface SearchBarProps {
    searchTerm: string;
    onSearch: (searchTerm: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({searchTerm, onSearch}) => {
    const [searchTermState, setSearchTermState] = useState<string>(searchTerm);

    
    useEffect(() => {
        setSearchTermState(searchTerm);
    }, [searchTerm]);
    
    const searchTermChange = (searchTerm: string) => {
        onSearch(searchTerm);
        setSearchTermState(searchTerm);
    }

    const isActive = searchTermState.length > 0;

    return(
        <div className={`dark-glass search-box`}>
            <input
                type="text"
                className="search-input"
                placeholder="Search pairings..."
                value={searchTerm}
                onChange={(e) => searchTermChange(e.target.value)}/>
            <a className="search-btn" href="#">
                {isActive ? (
                    <ImCancelCircle className={`search-icon active-icon`} onClick={() => searchTermChange("")}/>
                ) : (
                    <FaSearch className={`search-icon`} />
                )}
            </a>
        </div>
    )
}


export default SearchBar;