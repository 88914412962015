import React, { useEffect, useState } from 'react';
import "./FilterButton.scss"
import { FaRegStar, FaStar, FaFlagCheckered, FaSortAlphaDown } from 'react-icons/fa';
import { FaRankingStar, FaRegCirclePlay, FaCirclePlay } from "react-icons/fa6";
import { PiListBulletsBold } from "react-icons/pi";
import { IoPeopleCircleOutline } from "react-icons/io5";
import { MdOutlineCloudUpload, MdCloudUpload } from "react-icons/md";


interface FilterButtonProps {
    isToggled: boolean;
    onToggle: () => void;
    iconType: FilterButtonType;
}


export enum FilterButtonType {
    ToWatch,
    FeatureMatch,
    StreamMatch,
    StillPlaying,
    Active,
    Finished,
    Alphabetical,
    AllStates,
    LastUpdated,
    Default
}


const FilterButton: React.FC<FilterButtonProps> = ({isToggled, onToggle, iconType}) => {
    const [toggledState, setToggledState] = useState<boolean>(false);

    useEffect(() => {
        setToggledState(isToggled);
    }, [isToggled, onToggle]);

    let icon = <FaStar />;
    let label: String = "";

    switch (iconType) {
        case FilterButtonType.ToWatch:
            icon = toggledState ? <FaStar className='filter-icon selected'/> : <FaRegStar className='filter-icon deselected'/>;
            label = "Player to Watch";
            break;
        case FilterButtonType.FeatureMatch:
            icon = toggledState ? <FaRankingStar className='filter-icon selected'/> : <FaRankingStar className='filter-icon deselected'/>;
            label = "Feature Match";
            break;
        case FilterButtonType.StreamMatch:
            icon = toggledState ? <IoPeopleCircleOutline className='filter-icon selected'/> : <IoPeopleCircleOutline className='filter-icon deselected'/>;
            label = "Stream Match";
            break;
        case FilterButtonType.StillPlaying:
            icon = toggledState ? <FaCirclePlay className='filter-icon selected'/> : <FaRegCirclePlay className='filter-icon deselected'/>;
            label = "Still Playing";
            break;
        case FilterButtonType.Active:
            icon = toggledState ? <FaCirclePlay className='filter-icon selected'/> : <FaRegCirclePlay className='filter-icon deselected'/>;
            label = "Active";
            break;
        case FilterButtonType.Finished:
            icon = toggledState ? <FaFlagCheckered className='filter-icon selected'/> : <FaFlagCheckered className='filter-icon deselected'/>;
            label = "Finished";
            break;
        case FilterButtonType.Alphabetical:
            icon = toggledState ? <FaSortAlphaDown className='filter-icon selected'/> : <FaSortAlphaDown className='filter-icon deselected'/>;
            label = "Alphabetical";
            break;
        case FilterButtonType.AllStates:
            icon = toggledState ? <PiListBulletsBold className='filter-icon selected'/> : <PiListBulletsBold className='filter-icon deselected'/>;
            label = "All States";
            break;
        case FilterButtonType.LastUpdated:
            icon = toggledState ? <MdCloudUpload className='filter-icon selected'/> : <MdOutlineCloudUpload className='filter-icon deselected'/>;
            label = "Last Updated";
            break;
        case FilterButtonType.Default:
            icon = toggledState ? <FaStar className='filter-icon selected'/> : <FaRegStar className='filter-icon deselected'/>;
            break;
    }

    return (
        <div className="filter-button" onClick={onToggle}>
            <div className='filter-label-container'>
                <label className='filter-label'>{label}</label>
            </div>
            {icon}
        </div>
    );
};

export default FilterButton;;