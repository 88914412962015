import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.scss';
import Home from '../Home/Home';
import EventsDashboard from '../EventsDashboard/EventsDashboard/EventsDashboard';
import CreateEvent from '../EventsDashboard/CreateEvent/CreateEvent';
import PairingsDashboard from '../PairingsDashboard/PairingsDashboard/PairingsDashboard';
import Navbar from '../components/navbar/Navbar';
import Background from '../components/background/Background';
import { navbarData } from '../components/navbar/Navbar.ViewModel';
import { useEffect } from 'react';
import ScorekeeperDashboard from '../ScorekeeperDashboard/ScorekeeperDashboard/ScorekeeperDashboard';
import { BehaviorSubject } from 'rxjs';

function App() {
  const navbarDataSubject = new BehaviorSubject<navbarData>(new navbarData());

  useEffect(() => {
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    if (metaThemeColor) {
      metaThemeColor.setAttribute('content', '#5233bd');
    }
  }, []);
  
  
  return (
    <div className="app">
      <div className="app-bgroot"><Background/></div>
        <div className="app-header">
          <Navbar dataSubject={navbarDataSubject}></Navbar>
        </div>
        <div className="app-content">     
          <Router>
            <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="/events" element={<EventsDashboard navbarDataSubject={navbarDataSubject}/>}/>
              <Route path="/create-event" element={<CreateEvent navbarDataSubject={navbarDataSubject} />}/>
              <Route path="/events/:eventId" element={<ScorekeeperDashboard navbarDataSubject={navbarDataSubject} />}/>
              <Route path="/events/:eventId/pairings" element={<PairingsDashboard navbarDataSubject={navbarDataSubject} isReadOnly={true}/>}/>
              <Route path="/events/:eventId/pairings/:roundNumber" element={<PairingsDashboard navbarDataSubject={navbarDataSubject} isReadOnly={true}/>}/>
              <Route path="/events/:eventId/stream" element={<PairingsDashboard navbarDataSubject={navbarDataSubject} isReadOnly={false}/>}/>
              <Route path="/events/:eventId/stream/:roundNumber" element={<PairingsDashboard navbarDataSubject={navbarDataSubject} isReadOnly={false}/>}/>
            </Routes>
          </Router>
        </div>
    </div>
  );
}

export default App;