export class navbarViewModel {
    title: string;
    subtitle: string;

    constructor(data: navbarData) {
        this.title = data.title;
        this.subtitle = data.subtitle;
        console.log("Navbar view model created with " + this.title + " and " + this.subtitle);
    }
}

export class navbarData {
    title: string = "";
    subtitle: string = "";

    constructor(title: string = "", subtitle: string = "") {
        if (title == "") {
            this.title = "Stream Data Tools";
        } else {
            this.title = title;
        }

        if (subtitle == "") {
            this.subtitle = "";
        } else {
            this.subtitle = subtitle;
        }
    }
}