import { motion, useAnimationControls } from "framer-motion";
import GlassCard from "../../components/glassTheme/GlassCard";
import '../../components/glassTheme/Common.scss';
import '../../components/glassTheme/Switch.scss';
import '../../components/glassTheme/NumberInput.scss';
import './Uploader.scss';
import React, { Fragment, useEffect, useState } from 'react';
import Loader, { SpinnerType } from "../../components/Spinner/Loader";
import { FaRegPaperPlane, FaTrophy, FaTape, FaMapPin, FaStar, FaCopy } from 'react-icons/fa';
import { PiListNumbersFill } from "react-icons/pi";
import { MdOutlineCloudUpload, MdOutlineRefresh } from "react-icons/md";
import { LuListChecks } from "react-icons/lu";
import { IoPeopleCircleOutline } from "react-icons/io5";
import { TbBuildingBroadcastTower } from "react-icons/tb";
import { apiSampleTabOptions, apiSampleViewModel, selectedStandingsViewModel, selectedTournamentFileViewModel } from "../ScorekeeperDashboard/ScorekeeperDashboard.ViewModel";
import { BehaviorSubject, Subscription } from "rxjs";
import { ApiConnectService } from "../../Services/ApiConnectService";
import AutoUploadControls from "../AutoUploadControls/AutoUploadControls";
import UploadTracker, { UploadTrackerIcon } from "../../components/UploadTracker/UploadTracker";
import { FaFlagCheckered } from 'react-icons/fa';
import { eventViewModel } from "../../EventsDashboard/EventsDashboard/EventsDashboard.ViewModel";
import { eventState } from "../../Models/Common/EventState";
import { competitorDivision } from "../../Models/Common/CompetitorDivision";

interface UploaderSubviewProps {
  eventId: string;
  apiSampleSubject: BehaviorSubject<apiSampleViewModel>;
  eventDataSubject: BehaviorSubject<eventViewModel | null>;
  tournamentFileSubject: BehaviorSubject<selectedTournamentFileViewModel>;
  standingsSubject: BehaviorSubject<selectedStandingsViewModel>;
  switchResultsTab : (tab: apiSampleTabOptions) => void;
  updateSelectedTournamentFile : (file: string) => void;
  updateSelectedStandingsValue : (value: string) => void;
  standingsReportDivisionChange : (value: competitorDivision) => void;
  manuallySetSelectedStandingsValue : (value: number) => void;
  uploadTournamentFile : (calculateStandings: boolean, isFinalUpload: boolean) => void;
  uploadStandings : () => void;
}

const standingsPlaceholder = "Paste Top 16 standings from TOM report here. Do not include the header row and check that the results are formatted correctly."
const acs = new ApiConnectService();

const Uploader: React.FC<UploaderSubviewProps> = ({
  eventId,
  apiSampleSubject,
  eventDataSubject,
  tournamentFileSubject,
  standingsSubject,
  switchResultsTab,
  updateSelectedTournamentFile,
  updateSelectedStandingsValue,
  standingsReportDivisionChange,
  manuallySetSelectedStandingsValue,
  uploadTournamentFile,
  uploadStandings }) => {

  const [apiSample, setApiSample] = useState<apiSampleViewModel>();
  const [eventData, setEventData] = useState<eventViewModel | null>(null);
  const [tournamentFile, setTournamentFile] = useState<selectedTournamentFileViewModel | null>(null);
  const [isTournamentFileSelectedManually, setIsTournamentFileSelectedManually] = useState<boolean>(false);
  const [standings, setStandings] = useState<selectedStandingsViewModel | null>(null);
  const [isFinalUpload, setIsFinalUpload] = useState<boolean>(false);
  const [shouldCalculateStandings, setShouldCalculateStandings] = useState<boolean>(true);
  const animationControl = useAnimationControls();

  useEffect(() => {
    var subs: Subscription[] = [];
    subs.push(apiSampleSubject.subscribe((value) =>
      {
        if (value.response !== "") {
          animationControl.start("onscreen");
        }
        setApiSample({...value});
      }));
    subs.push(eventDataSubject.subscribe(value =>
      {
        setEventData(value ? {...value} : null)
      }));
    subs.push(tournamentFileSubject.subscribe(value =>
      {
        if (value.file == "") {
          resetTournamentFile();
        }
        setTournamentFile({...value})
      }));
    subs.push(standingsSubject.subscribe(value => 
      {
        if (value.value == "") {
          resetStandings();
        }
        setStandings({...value})
      }));
    return () => subs.forEach(s => s.unsubscribe());
  }, [eventId, apiSampleSubject, tournamentFileSubject, standingsSubject, animationControl, eventDataSubject]);

  const animationVariants = {
    offscreen: { delay: 0, opacity: 0, scale: 0.6, transition: { duration: 0.5 } },
    onscreen: { delay: 0, opacity: 1, scale: 1, transition: { duration: 0.2 } }
  };

  const copyText = async () => {   
    var copyText = document.getElementById("resultText") as HTMLInputElement;
    if (!copyText) {
      return;
    }
    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices
    document.execCommand("Copy");
    copyText.blur();
  }

  const manuallySelectedTournamentFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;
    if (!input || !input.files?.length) {
      return;
    }
    var file = input.files[0];
    var reader = new FileReader()
    reader.onload = async (e: ProgressEvent<FileReader>) => { 
      if (!e.target?.result) {
        return;
      }
      setIsTournamentFileSelectedManually(true);
      updateSelectedTournamentFile(e.target.result as string);
    };
    reader.readAsText(file)
  }

  const automaticallySelectedTournamentFile = async (file: string) => {
    setIsTournamentFileSelectedManually(false);
    updateSelectedTournamentFile(file);
  }

  const resetTournamentFile = () => {
    var tdfFilerSelector = document.getElementById("tdfFilerSelector") as HTMLInputElement;
    if (!tdfFilerSelector) {
      return;
    }
    tdfFilerSelector.value = "";
  }

  const resetStandings = () => {
    var standingsTextArea = document.getElementById("standingsMessage") as HTMLInputElement;
    if (!standingsTextArea) {
      return;
    }
    standingsTextArea.value = "";
  }

  return (
    <Fragment>
      <div className='uploadElements'>
        { eventData?.state == eventState.Finished &&
              <div className="eventStateBanner"> 
                  <div className="eventStateBannerData">
                    <FaFlagCheckered className="eventStateBannerIcon"/>
                    <label className="glass-h1">Event Complete!</label>
                  </div>
                  <label className="glass-body">The final tournament file has been uploaded. Please do not push any new data unless it's a valid update.</label>
              </div>
        }
        <div className="cardWrapper">
          <GlassCard>
            <div className="cardInsertWithFooter">
              <label className="glass-title">Tournament File</label>
              <label className="glass-body">{tournamentFile?.gameType}</label>
              <label className="glass-body">{tournamentFile?.fileName}</label>
              <br/>

              <div className='radioBox'>
                <label className="glass-switch">
                <input
                    type="checkbox"
                    disabled={true}
                    checked={true}/>
                    <i></i></label>
                <label className="glass-body">Remove country codes</label>
              </div>
              <div className='radioBox'>
                <label className="glass-switch">
                <input
                  type="checkbox"
                  checked={shouldCalculateStandings}
                  onChange={(e) => setShouldCalculateStandings(!shouldCalculateStandings)}/>
                <i></i>
                </label>
                <label className="glass-body">Calculate standings</label>
              </div>
              <div className='radioBox'>
                <label className="glass-switch">
                <input
                  type="checkbox"
                  checked={isFinalUpload}
                  onChange={(e) => setIsFinalUpload(!isFinalUpload)}/>
                <i></i>
                </label>
                <label className="glass-body">Is final tournament file</label>
              </div>
              <br/>
              <div className="glass-divider"/>
              <AutoUploadControls
                updateSelectedTournamentFile={automaticallySelectedTournamentFile}
                shouldCalculateStandings={shouldCalculateStandings} 
                isFinalUpload={isFinalUpload} 
                uploadTournamentFile={uploadTournamentFile} />
              <div className="glass-divider"/>
              <br/>
              <label className="glass-h2">Manual Upload</label>
              <form className="radioForm">
              <input
                className="glass-fileSelect"
                id="tdfFilerSelector"
                type="file"
                name="file"
                accept=".tdf"
                onChange={(e) => manuallySelectedTournamentFile(e)}
                style={{ display: "block", margin: "10px auto" }}
              />
              </form>
              {isTournamentFileSelectedManually ?
                <div className="actionContainer">
                  <button className="uploader-button" onClick={(e) => uploadTournamentFile(shouldCalculateStandings, isFinalUpload)} disabled={tournamentFile?.isUploading}>Upload File <FaRegPaperPlane className="glass-button-icon-right"/></button>
                </div>
                : null
              }
            </div>
            </GlassCard>
            <div className="card-footer">
              { tournamentFile &&
                <UploadTracker
                  isUploading={tournamentFile?.isUploading}
                  uploadResult={tournamentFile?.uploadResult}
                  defaultIcon={UploadTrackerIcon.Document}
                  defaultTitle={"Select a tournament file"}
                  defaultSubtitle={eventData?.pairingsReadableLastUpdated ? "Last upload: " + eventData?.pairingsReadableLastUpdated : "Upload information will appear here."}
                  uploadMessage="Uploading TDF..."/>
              }
            </div>
          </div>

        <div className="cardWrapper">
          <GlassCard>
            <div className="cardInsertWithFooter">
              <label className="glass-title">Standings Report</label>
              <div className='standingsForm'>
                <textarea className="glass-textarea" id="standingsMessage" placeholder={standingsPlaceholder} onChange={(e) => updateSelectedStandingsValue(e.target.value)}></textarea>
                <div className="standingsControls">
                  <select className="glass-select" value={standings?.division} onChange={(e) => {
                      standingsReportDivisionChange(competitorDivision[e.target.value as keyof typeof competitorDivision] as competitorDivision);
                    }}>
                    {Object.values(competitorDivision)
                      .filter((v) => !isNaN(parseInt(v as string)))
                      .map(x => (
                          <option key={crypto.randomUUID()} value={parseInt(x as string)}>{competitorDivision.name(parseInt(x as string) as competitorDivision)}</option>
                    ))}
                  </select>
                  <div className='radioBox'>
                    <input
                      className="glass-number-input"
                      type="number"
                      name="round"
                      min="1"
                      max="20"
                      value={standings?.round ?? ""}
                      onChange={(e) => manuallySetSelectedStandingsValue(e.target.valueAsNumber)}
                    />
                    <label className="glass-body">Round number</label>
                  </div>
                  <div className='radioBox'>
                  <label className="glass-switch">
                    <input
                        type="checkbox"
                        disabled={true}
                        checked={true}/>
                        <i></i></label>
                        <label className="glass-body">Remove country codes</label>
                  </div>
                </div>
                <div className="actionContainer">
                  <button className="uploader-button" onClick={(e) => {uploadStandings()}} hidden={standings?.isUploading}>Upload Standings <FaTrophy className="glass-button-icon-right"/></button>
                  {standings?.isUploading && (<Loader showMessage={false} spinnerType={SpinnerType.FullPage}/>)}
                </div>
              </div>
              <label className="glass-body">{standings?.uploadResult?.summaryTitle}</label>
              <label className="glass-body">{standings?.calculationMessage}</label>
            </div>
          </GlassCard>
            <div className="card-footer">
              { standings &&
                <UploadTracker
                  isUploading={standings?.isUploading}
                  uploadResult={standings?.uploadResult}
                  defaultIcon={UploadTrackerIcon.Report}
                  defaultTitle={"Paste a standings report"}
                  defaultSubtitle={eventData?.standingsReadableLastUpdated ? "Last upload: " + eventData?.standingsReadableLastUpdated : "Upload information will appear here."}
                  uploadMessage="Uploading TDF..."/>
              }
            </div>
        </div>

        <motion.div
          initial="offscreen"
          animate={animationControl}
          variants={animationVariants}
          className="resultCollection">
          <div className="resultContainerTabs">
            <button className={"tabButton " + (apiSample?.selectedTab == apiSampleTabOptions.endOfRound ? 'tabSelected' : '')} onClick={(e) => switchResultsTab(apiSampleTabOptions.endOfRound)}>Remaining Tables</button>
            <button className={"tabButton " + (apiSample?.selectedTab == apiSampleTabOptions.pairings ? 'tabSelected' : '')} onClick={(e) => switchResultsTab(apiSampleTabOptions.pairings)}>Pairings</button>
            <button className={"tabButton " + (apiSample?.selectedTab == apiSampleTabOptions.liveResults ? 'tabSelected' : '')} onClick={(e) => switchResultsTab(apiSampleTabOptions.liveResults)}>Live Results</button>
            <button className={"tabButton " + (apiSample?.selectedTab == apiSampleTabOptions.standings ? 'tabSelected' : '')} onClick={(e) => switchResultsTab(apiSampleTabOptions.standings)}>Standings</button>
          </div>
          <div className="dark-glass-card resultContainer">
            <textarea className="resultMessage" id="resultText" value={apiSample?.response} readOnly></textarea>
            { apiSample && !apiSample?.isLoading ?
              <div className="actionButtonContainer">
                <div className="actionButtonWrapper" onClick={(e) => copyText()}>
                  <FaCopy className="actionButton"/>
                </div>
                  <div className="actionButtonWrapper" onClick={(e) => switchResultsTab(apiSample!.selectedTab)}>
                    <MdOutlineRefresh className="actionButton"/>
                  </div>
              </div>
              :
              <div className="loaderOverlay">
                <Loader showMessage={false} spinnerType={SpinnerType.Circular}/>
              </div>
            }
          </div>

          <div className="dark-glass-card apiEndpointsContainer">
            <div className="apiEndpoint">
                <label className="glass-h2">API endpoints:</label>
              </div>
              <div className="apiEndpoint">
                  <FaMapPin className="smallIcon"/>
                <a className="glass-body" href={acs.apiEventUrl+eventId+"/pairings"} target="_blank">/ api / events / {eventId} / pairings</a>
              </div>

              <div className="apiEndpoint">
                  <FaTape className="smallIcon"/>
                <a className="glass-body" href={acs.apiEventUrl+eventId+"/stream-feed"} target="_blank">/ api / events / {eventId} / stream-feed</a>
              </div>

              <div className="apiEndpoint">
                  <PiListNumbersFill className="smallIcon"/>
                <a className="glass-body" href={acs.apiEventUrl+eventId+"/pairings/round/1"} target="_blank">/ api / events / {eventId} / pairings / round / (number)</a>
              </div>

              <div className="apiEndpoint">
                  <FaStar className="smallIcon"/>
                <a className="glass-body" href={acs.apiEventUrl+eventId+"/standings"} target="_blank">/ api / events / {eventId} / standings</a>
              </div>
            </div>

            <div className="dark-glass-card apiEndpointsContainer">
            <div className="apiEndpoint">
                <label className="glass-h2">Dashboards:</label>
              </div>
              <div className="apiEndpoint">
                  <LuListChecks className="smallIcon"/>
                <a className="glass-body" href={"/events"} target="_blank">List all events</a>
              </div>

              <div className="apiEndpoint">
                  <MdOutlineCloudUpload className="smallIcon"/>
                <a className="glass-body" href={"/events/"+eventId+"/"} target="_blank">Upload to {eventId}</a>
              </div>

              <div className="apiEndpoint">
                  <IoPeopleCircleOutline className="smallIcon"/>
                <a className="glass-body" href={"/events/"+eventId+"/pairings"} target="_blank">Pairings for {eventId}</a>
              </div>

              <div className="apiEndpoint">
                  <TbBuildingBroadcastTower className="smallIcon"/>
                <a className="glass-body" href={"/events/"+eventId+"/stream"} target="_blank">Stream data controls for {eventId}</a>
              </div>
            </div>
        </motion.div>
      </div>
    </Fragment>
  );
}
export default Uploader;