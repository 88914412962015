import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './Home.scss';

function Home() {
  return (
    <div className="home">
      <div className='menu-items'>
        <a className="menu-item sway-left" href={"/events"} target="">
            <label className="glass-title menu-item-text">All events</label>
            <label className="glass-body menu-item-text"><i>Requires sign in</i></label>
        </a>
        <a className="menu-item sway-right" href={"/create-event"} target="">
            <label className="glass-title menu-item-text">Create new event</label>
            <label className="glass-body menu-item-text"><i>Requires sign in</i></label>
        </a>
      </div>
    </div>
  );
}

export default Home;