import { roundPairingMatch } from "../../Models/Outbound/RoundPairingMatch";
import { roundPairingCompetitor } from "../../Models/Outbound/RoundPairingCompetitor";
import { streamMatchTransfer } from "../../Models/Inbound/StreamMatchTransfer";
import { matchOutcome } from "../../Models/Common/MatchOutcome";

export class matchViewModel {
    tableNumber: number;
    outcome: matchOutcome;
    roundNumber: number;
    player1: competitorViewModel;
    player2: competitorViewModel | null;
    isStreamMatch: boolean = false;
    isFeatureMatch: boolean = false;
    shouldSwitchPlayers: boolean = false;
    resultMessage: string;

    constructor(apiMatch: roundPairingMatch, roundNumber: number) {
        this.tableNumber = apiMatch.tableNumber;
        this.outcome = apiMatch.outcome;
        this.roundNumber = roundNumber;
        this.resultMessage = apiMatch.resultMessage;
        this.player1 = new competitorViewModel(
            apiMatch.player1.playerId,
            apiMatch.player1.firstName,
            apiMatch.player1.lastName,
            apiMatch.player1.countryCode ?? null,
            apiMatch.player1.wins,
            apiMatch.player1.losses,
            apiMatch.player1.ties
        );
        if (apiMatch.player2) {
            this.player2 = new competitorViewModel(
                apiMatch.player2.playerId,
                apiMatch.player2.firstName,
                apiMatch.player2.lastName,
                apiMatch.player2.countryCode ?? null,
                apiMatch.player2.wins,
                apiMatch.player2.losses,
                apiMatch.player2.ties
            );
        } else {
            this.player2 = null;
        }
    }

    toStreamMatchViewModel(): streamMatchViewModel {
        return new streamMatchViewModel(
            this.tableNumber,
            this.outcome,
            this.roundNumber,
            this.player1,
            this.player2,
            this.resultMessage,
            this.shouldSwitchPlayers)
    }
}

export class streamMatchViewModel {
    tableNumber: number;
    outcome: matchOutcome;
    roundNumber: number;
    player1: competitorViewModel;
    player2: competitorViewModel | null;
    isStreamMatch: boolean = false;
    isFeatureMatch: boolean = false;
    resultMessage: string;
    featureMatchIndex: number = 0;
    shouldSwitchPlayers: boolean = false;

    constructor(
        tableNumber: number,
        outcome: matchOutcome,
        roundNumber: number,
        player1: competitorViewModel,
        player2: competitorViewModel | null,
        resultMessage: string,
        shouldSwitchPlayers: boolean,
        featureMatchIndex: number | null = null,
    ) {
        this.tableNumber = tableNumber;
        this.outcome = outcome;
        this.roundNumber = roundNumber;
        this.player1 = player1;
        this.player2 = player2;
        this.resultMessage = resultMessage;
        this.featureMatchIndex = featureMatchIndex ?? 0;
        this.shouldSwitchPlayers = shouldSwitchPlayers;
    }
    
    toStreamMatchTransfer(): streamMatchTransfer {
        return {
            player1: this.player1.toRoundPairingCompetitor(),
            player2: this.player2?.toRoundPairingCompetitor() ?? null,
            tableNumber: this.tableNumber,
            outcome: this.outcome,
            round: this.roundNumber,
            resultMessage: this.resultMessage,
            shouldSwitchPlayers: this.shouldSwitchPlayers,
            featureMatchIndex: this.featureMatchIndex,
        };
    }

    deepCopy(): streamMatchViewModel {
        return new streamMatchViewModel(
            this.tableNumber,
            this.outcome,
            this.roundNumber,
            this.player1,
            this.player2,
            this.resultMessage,
            this.shouldSwitchPlayers,
            this.featureMatchIndex
        );
    }
}

export class competitorViewModel {
    playerId: string;
    name: string;
    firstName: string;
    lastName: string;
    countryCode: string | null;
    wins: number;
    losses: number;
    ties: number;
    isPlayerToWatch: boolean = false;

    constructor(playerId: string, firstName: string, lastName: string, countryCode: string | null, wins: number, losses: number, ties: number) {
        this.playerId = playerId;
        this.firstName = firstName;
        this.lastName = lastName;
        this.countryCode = countryCode ?? null;
        this.name = `${firstName} ${lastName}`;
        this.wins = wins;
        this.losses = losses;
        this.ties = ties;
    }

    toRoundPairingCompetitor(): roundPairingCompetitor{
        return {
            playerId: this.playerId,
            firstName: this.firstName,
            lastName: this.lastName,
            countryCode: this.countryCode,
            wins: this.wins,
            losses: this.losses,
            ties: this.ties
        };
    }
}