export enum gameType {
        TradingCardGame,
        VideoGame,
        GO,
        Other,
}

export namespace gameType {
        export function name(state: gameType): string {
                switch (state) {
                        case gameType.TradingCardGame:
                                return "Trading Card Game";
                        case gameType.VideoGame:
                                return "Video Game";
                        case gameType.GO:
                                return "Pokémon GO";
                        case gameType.Other:
                                return "Other";
                }
        }
        export function shortName(state: gameType): string {
                switch (state) {
                        case gameType.TradingCardGame:
                                return "TCG";
                        case gameType.VideoGame:
                                return "VG";
                case gameType.GO:
                        return "GO";
                        case gameType.Other:
                                return "Other";
                }
        }
}