export enum eventSeason {
        S2020 = 20,
        S2021 = 21,
        S2022 = 22,
        S2023 = 23,
        S2024 = 24,
        S2025 = 25,
}

export namespace eventSeason {
        export function name(state: eventSeason): string {
                switch (state) {
                        case eventSeason.S2020:
                                return "2020";
                        case eventSeason.S2021:
                                return "2021";
                        case eventSeason.S2022:
                                return "2022";
                        case eventSeason.S2023:
                                return "2023";
                        case eventSeason.S2024:
                                return "2024";
                        case eventSeason.S2025:
                                return "2025";
        }
        }
}
