import { roundPairingWrapper } from "../../Models/Outbound/RoundPairingWrapper";
import { matchOutcome } from "../../Models/Common/MatchOutcome";
import { matchViewModel } from "../Match/Match.ViewModel";

export class pairingsViewModel {
    eventId: string;
    lastUpdated: string;
    roundNumber: number;
    division: string;
    matches : matchViewModel[];

    constructor(apiPairings: roundPairingWrapper) {
        console.log("Updating dashboard pairings")
        this.eventId = apiPairings.eventId;
        this.lastUpdated = apiPairings.lastUpdated;
        this.roundNumber = apiPairings.round;
        this.division = "Master" // Temporarily hard-coded until division is added to the RoundPairingWrapper model;
        this.matches = [];
        apiPairings.roundPairingMatches.forEach(match => {
            try {
                var convertedMatch = new matchViewModel(match, this.roundNumber)
                this.matches.push(convertedMatch);
            } catch (error) { 
                console.log("Error converting match: " + error)
            }
        });
    }
}